var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"print-mockup",class:_vm.selectedDevice},[(_vm.coupon.type === 'graphic' || _vm.coupon.type === 'popup')?_c('div',{staticClass:"sms-widget"},[_c('div',{ref:"widget",style:(_vm.smsWidgetContainerStyle)},[(_vm.coupon.sections.logo.Image)?_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.logo.Image },on:{"click":function($event){return _vm.selectBlock('logo')}}},[_c('div',{staticClass:"logo-box",style:({ textAlign: _vm.coupon.sections.logo.Align })},[_c('a',{attrs:{"href":_vm.coupon.sections.logo.Link}},[_c('img',{style:({
                  maxWidth: _vm.coupon.sections.logo.MaxWidth + 'px',
                  width: '100%',
                }),attrs:{"src":_vm.coupon.sections.logo.Image,"alt":"Logo"}})])])]):_vm._e(),(_vm.coupon.sections.heroimage.Image)?_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.heroimage.Image },on:{"click":function($event){return _vm.selectBlock('heroimage')}}},[_c('div',{staticClass:"heroimage-box",style:({ textAlign: _vm.coupon.sections.heroimage.Align })},[_c('img',{style:({
                maxWidth: _vm.coupon.sections.heroimage.MaxWidth + 'px',
                width: '100%',
              }),attrs:{"src":_vm.coupon.sections.heroimage.Image,"alt":"Logo"}})])]):_vm._e(),(_vm.coupon.sections.headline.Content)?_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.headline.Content },on:{"click":function($event){return _vm.selectBlock('headline')}}},[_c('div',{style:({
              color: _vm.coupon.sections.headline.Color,
              fontSize: ((_vm.coupon.sections.headline.FontSize) + "px"),
              textAlign: ("" + (_vm.coupon.sections.headline.Align)),
              letterSpacing: '0.001px',
            })},[_vm._v(" "+_vm._s(_vm.coupon.sections.headline.Content)+" ")])]):_vm._e(),(_vm.subheadingContent)?_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.subheadingContent },on:{"click":function($event){return _vm.selectBlock('subheading')}}},[_c('div',{style:({
              color: _vm.coupon.sections.subheading.Color,
              fontSize: ((_vm.coupon.sections.subheading.FontSize) + "px"),
              textAlign: ("" + (_vm.coupon.sections.subheading.Align)),
              letterSpacing: '0.001px',
            })},[_vm._v(" "+_vm._s(_vm.subheadingContent)+" ")])]):_vm._e(),_c('div',{staticClass:"widget-copyright",style:({ textAlign: ("" + (_vm.coupon.sections.legalFooter.Align)) }),on:{"click":function($event){return _vm.selectBlock('legalFooter')}}},[_c('a',{style:({
              color: _vm.coupon.sections.legalFooter.Color,
              fontSize: ((_vm.coupon.sections.legalFooter.FontSize) + "px"),
            })},[_vm._v(" "+_vm._s(_vm._f("legal")(_vm.coupon.sections.legalFooter.PrintContent))+" ")])])])]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.loadingExportImage},on:{"click":_vm.exportImage}},[(_vm.loadingExportImage)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Export image")])],1)])]),_c('b-modal',{attrs:{"title":"Export image","size":"lg","modal-class":"modal-export-image"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('a',{ref:"exportImageLink",staticClass:"btn btn-primary",attrs:{"download":"coupon"}},[_c('i',{staticClass:"uil uil-download"}),_vm._v(" Download ")]),_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){_vm.showImageModal = false}}},[_vm._v("Cancel")])]},proxy:true}]),model:{value:(_vm.showImageModal),callback:function ($$v) {_vm.showImageModal=$$v},expression:"showImageModal"}},[_c('div',{ref:"exportImage"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }