<template>
  <div>
    <div class="print-mockup" :class="selectedDevice">
      <div
        v-if="coupon.type === 'graphic' || coupon.type === 'popup'"
        class="sms-widget"
      >
        <div ref="widget" :style="smsWidgetContainerStyle">
          <div
            v-if="coupon.sections.logo.Image"
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.logo.Image }"
            @click="selectBlock('logo')"
          >
            <div
              class="logo-box"
              :style="{ textAlign: coupon.sections.logo.Align }"
            >
              <a :href="coupon.sections.logo.Link">
                <img
                  :src="coupon.sections.logo.Image"
                  alt="Logo"
                  :style="{
                    maxWidth: coupon.sections.logo.MaxWidth + 'px',
                    width: '100%',
                  }"
                />
              </a>
            </div>
          </div>
          <div
            v-if="coupon.sections.heroimage.Image"
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.heroimage.Image }"
            @click="selectBlock('heroimage')"
          >
            <div
              class="heroimage-box"
              :style="{ textAlign: coupon.sections.heroimage.Align }"
            >
              <img
                :src="coupon.sections.heroimage.Image"
                alt="Logo"
                :style="{
                  maxWidth: coupon.sections.heroimage.MaxWidth + 'px',
                  width: '100%',
                }"
              />
            </div>
          </div>
          <div
            v-if="coupon.sections.headline.Content"
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.headline.Content }"
            @click="selectBlock('headline')"
          >
            <div
              :style="{
                color: coupon.sections.headline.Color,
                fontSize: `${coupon.sections.headline.FontSize}px`,
                textAlign: `${coupon.sections.headline.Align}`,
                letterSpacing: '0.001px',
              }"
            >
              {{ coupon.sections.headline.Content }}
            </div>
          </div>
          <div
            v-if="subheadingContent"
            class="widget-block"
            :class="{ 'widget-content': subheadingContent }"
            @click="selectBlock('subheading')"
          >
            <div
              :style="{
                color: coupon.sections.subheading.Color,
                fontSize: `${coupon.sections.subheading.FontSize}px`,
                textAlign: `${coupon.sections.subheading.Align}`,
                letterSpacing: '0.001px',
              }"
            >
              {{ subheadingContent }}
            </div>
          </div>
          <div
            class="widget-copyright"
            :style="{ textAlign: `${coupon.sections.legalFooter.Align}` }"
            @click="selectBlock('legalFooter')"
          >
            <a
              :style="{
                color: coupon.sections.legalFooter.Color,
                fontSize: `${coupon.sections.legalFooter.FontSize}px`,
              }"
            >
              {{ coupon.sections.legalFooter.PrintContent | legal }}
            </a>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <button
          class="btn btn-success"
          :disabled="loadingExportImage"
          @click="exportImage"
        >
          <b-spinner v-if="loadingExportImage" small />
          <span v-else>Export image</span>
        </button>
      </div>
    </div>

    <b-modal
      v-model="showImageModal"
      title="Export image"
      size="lg"
      modal-class="modal-export-image"
    >
      <div ref="exportImage"></div>
      <template v-slot:modal-footer>
        <a ref="exportImageLink" class="btn btn-primary" download="coupon">
          <i class="uil uil-download"></i> Download
        </a>
        <b-button variant="light" @click="showImageModal = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'

export default {
  props: {
    coupon: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isShowPopup: true,
      selectedDevice: 'desktop',
      loadingExportImage: false,
      showImageModal: false,
    }
  },

  computed: {
    subheadingContent() {
      let content = this.coupon.sections.subheading.PrintContent

      if (content)
        content = content
          .replace(/<keyword>/, `"${this.coupon.keyword}"`)
          .replace(/<phone number>/, this.user.business.phone_number)
      return content
    },

    user() {
      return this.$store.getters['auth/user']
    },

    smsWidgetContainerStyle() {
      return {
        width: `${this.coupon.sections.styling.MaxWidth}px`,
        background: this.coupon.sections.styling.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.coupon.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
        borderRadius: `${this.coupon.sections.styling.Roudness}px`,
        boxShadow: '0 5px 25px 0 rgba(0,0,0,.20)',
        padding: '20px',
        zIndex: 10,
        transition: 'all 0.2s ease',
        transformOrigin: 'bottom right',
      }
    },

    triggerButtonStyle() {
      return {
        background: this.coupon.sections.triggerButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.triggerButton.BorderColor || 'transparent',
        color: this.coupon.sections.triggerButton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.triggerButton.Roudness}px`,
        fontSize: `${this.coupon.sections.triggerButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        zIndex: 5,
      }
    },

    mainButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mainbutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mainbutton.BorderColor || 'transparent',
        color: this.coupon.sections.mainbutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mainbutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mainbutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },

    mobileButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mobilebutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mobilebutton.BorderColor || 'transparent',
        color: this.coupon.sections.mobilebutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mobilebutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mobilebutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },
  },

  watch: {
    coupon(val) {
      this.isShowPopup = false
      if (val.type === 'graphic') {
        this.isShowPopup = true
      }
    },
  },

  mounted() {
    this.isShowPopup = this.coupon && this.coupon.type === 'graphic'
  },

  methods: {
    selectBlock(block) {
      this.$emit('selected-block', block)
    },

    togglePopup(isShow) {
      this.isShowPopup = isShow
    },

    selectDevice(device) {
      this.selectedDevice = device
    },

    exportImage() {
      this.loadingExportImage = true
      let element = this.$refs.widget

      html2canvas(element, {
        proxy: `${process.env.VUE_APP_BASE_API}api/v1/proxy-image`,
        scale: 2,
        backgroundColor: null,
        scrollX: 0,
        scrollY: window.scrollY * -1,
      }).then((canvas) => {
        this.showImageModal = true
        this.$nextTick(() => {
          canvas.id = 'export-image-canvas'
          this.$refs.exportImage.appendChild(canvas)
          this.$refs.exportImageLink.href = canvas.toDataURL('image/png', 100)
          this.loadingExportImage = false
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sms-widget {
  margin-bottom: 30px;
  .widget-block {
    margin-bottom: 15px;
    border: 2px dashed transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: border 300ms ease, background-color 300ms ease;
    margin-bottom: 1.4vh;
    position: relative;
    &.widget-content {
      border-color: transparent !important;
    }
    &:hover {
      border-color: #2399f0 !important;
      background-color: rgba(35, 153, 240, 0.05);
    }
  }
  .widget-copyright {
    cursor: pointer;
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
    > div:hover {
      -webkit-animation: swing 1s ease;
      animation: swing 1s ease;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    .phone-code {
      position: absolute;
      top: 10px;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      border: 1px solid #e2e7f1;
      padding: 10px 12px;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }

  @-webkit-keyframes swing {
    15% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }
    30% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    50% {
      -webkit-transform: translateX(3px);
      transform: translateX(3px);
    }
    65% {
      -webkit-transform: translateX(-3px);
      transform: translateX(-3px);
    }
    80% {
      -webkit-transform: translateX(2px);
      transform: translateX(2px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes swing {
    15% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }
    30% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    50% {
      -webkit-transform: translateX(3px);
      transform: translateX(3px);
    }
    65% {
      -webkit-transform: translateX(-3px);
      transform: translateX(-3px);
    }
    80% {
      -webkit-transform: translateX(2px);
      transform: translateX(2px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
</style>
